@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");

.showcase {
  font-family: "Oswald", sans-serif;
  margin: 0 auto;
  margin-top: 1%;
  background-image: url(../images/main_img.jpg);
  width: 80%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.show_title {
  box-sizing: border-box;
  margin: 0;
  padding-top: 3%;
  font-weight: 300;
  font-size: 3em;
  color: #1a5e8c;
  margin-left: 5%;
  text-shadow: 3px 3px 3px rgb(255, 254, 254);
}
.title2 {
  margin: 0;
  font-weight: 200;
  font-size: 2em;
  color: #1a5e8c;
  margin-left: 5%;
  text-shadow: 2px 2px 1px rgb(255, 254, 254);
}

.contact-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 13%;
  background: rgba(26, 94, 140, 0.43);
}
.contact-text {
  margin: 0;
  padding: 1%;
  margin-left: 5%;
  font-weight: 500;
  font-size: 2em;
  color: #fff;
}

p {
  margin: 0;
  padding-top: 0;
  padding: 1%;
  margin-left: 5%;
  font-weight: 200;
  font-size: 1.5em;
  color: #fff;
  width: 60%;
}

.contact-a {
  display: inline-block;
  background-color: #f05a29;
  box-sizing: border-box;
  font-size: 1em;
  border-radius: 0.5em;
  font-family: "Oswald", sans-serif;
  color: #fff;
  margin-left: auto;
  margin-bottom: 5%;
  margin-right: 5%;
  text-align: center;
  padding: 0.35em 1.2em;
  transition: 0.5s ease-in-out;
  z-index: 8;
}

.contact-a:hover {
  background-color: #1a5e8c;
}

.contact-a:active {
  transform: scale(0.95);
}

@media only screen and (max-width: 414px) {
  .showcase {
    background-position-x: -250px;
    background-position-y: 100px;
    background-size: 750px;
  }
  .contact-a {
    display: block;
    margin: 0 auto;
    width: 100%;
    margin-top: 5%;
  }
  .show_title {
    text-align: center;
    padding-top: 5%;
    margin-left: 0;
    font-size: 2em;
    line-height: 1.2;
    width: 80%;
    margin: 0 auto;
  }
  .title2 {
    text-align: center;
    margin-left: 0;
    font-size: 1.3em;
    margin-top: 5%;
    font-weight: 300;
    padding: 2%;
  }
  p {
    margin: 0;
    width: 100%;
    padding: 0;
    font-size: 1.1em;
    text-align: center;
  }

  .contact-container {
    margin-top: 50%;
  }
  .contact-text {
    text-align: center;
    font-size: 1.3em;
  }
}
