.ponuda-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #1a5e8c;
  height: 100%;
}

.pon-title {
  font-family: "Oswald";
  box-sizing: border-box;
  margin: 0;
  margin-bottom: 2%;
  margin-top: 5%;
  padding-top: 2%;
  font-weight: 700;
  font-size: 3vw;
  color: #fff;
}

.cards-container {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #1a5e8c;
  cursor: pointer;
}
.card {
  width: 400px;
  display: flex;
  flex-direction: column;
  margin-top: 1%;
  box-sizing: border-box;
  padding: 2%;
  transition: 0.5s ease;
  box-shadow: 0px 7px 10px rgba(black, 0.5);
}
.card:hover {
  transform: translateY(-10px);
}

.card:hover:before {
  opacity: 1;
}

.card:hover .text-ponuda {
  opacity: 1;
  transform: translateY(0px);
}

.text-ponuda {
  font-family: "Oswald";
  font-size: 1vw;
  text-align: center;
  margin: 0 auto;
  width: 90%;
  position: relative;
  z-index: 3;
  color: white;
  opacity: 0;
  transform: translateY(-40px);
  transition: 0.5s;
}

.card:before {
  content: "";
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 0;
  transition: 0.5s;
  opacity: 0;
}

.name-ponuda {
  box-sizing: border-box;
  font-family: "Oswald";
  font-weight: 300;
  font-size: 1.1vw;
  text-align: center;
  color: #fff;
  background-color: #1a5e8c;
  width: 380px;
  margin: auto;
  padding-top: 3%;
}

.img-ponuda {
  width: 100%;
  box-shadow: 0px 0px 18px -3px rgba(143, 143, 143, 0.5);
  border-radius: 15px;
}
.img-ponuda:hover {
  box-shadow: 0px 0px 20px -3px rgba(255, 255, 255, 0.75);
}

@media only screen and (max-width: 1000px) {
  .card {
    width: 200px;
  }
  .name-ponuda {
    width: 180px;
  }
}

@media only screen and (max-width: 768px) {
  .name-ponuda {
    font-size: 1em;
  }
  .pon-title {
    font-size: 1.3em;
  }
  .cards-container {
    width: 100%;
  }
  .text-ponuda {
    font-size: 0.5em;
  }
}
