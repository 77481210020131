.navbar {
  background-color: #f05a29;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.info {
  font-family: "Oswald", sans-serif;
  font-weight: 200;
  position: absolute;
  left: 2%;
  transition: 0.4s;
  font-size: 1vw;
}

.info:hover {
  color: #1a5e8c;
}
a {
  color: #fff;
  text-decoration: none;
}
.navbar ul {
  margin: 0;
  font-family: "Oswald", sans-serif;
  font-weight: 200;
  list-style: none;
  display: flex;
  flex-direction: row;
}

.navlink {
  padding: 0px 30px;
  font-size: 1rem;
  font-weight: 300;
  transition: 0.3s ease-in-out;
}

.navlink:hover {
  background-color: #1a5e8c;
  transition: 0.7s ease;
}
.active {
  background-color: #1a5e8c;
}
@media only screen and (max-width: 1200px) {
  .navlink {
    font-size: 0.7rem;
  }
}

@media only screen and (max-width: 768px) {
  .navlink,
  .info {
    display: none;
  }
}
