@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");

:root {
  --main-color: #1a5e8c;
}

body {
  background-image: linear-gradient(
    90deg,
    #1a5e8c 10%,
    white 10%,
    white 90%,
    #1a5e8c 90%
  );
  height: 100vh;
}

.br-line {
  margin-top: 2%;
  margin-bottom: 2%;
  border: 1px solid #f05a29;
  width: 74%;
}
