.load-container {
  display: flex;
  justify-content: center;

  height: 100vh;
}
.loadLogo {
  margin: 0 auto;
  height: 100vh;
}
