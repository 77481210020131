@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");

.header {
  display: flex;
  align-items: center;
}

.number {
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  box-sizing: border-box;
  text-align: center;
  width: 25%;
  color: #fff;
  background-color: #1a5e8c;
  font-size: 2.3vw;
}

.logo {
  width: 4%;
  padding: 0 10px;
}

.title {
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  font-size: 2.5vw;
  box-sizing: border-box;
  text-align: center;
  width: 50%;
  color: #fff;
  background-color: #1a5e8c;
  border-radius: 0 30px 30px 0;
}

.social_icons {
  display: flex;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  margin-right: 1%;
}
.icon {
  height: 36px;
  transition: 0.5s ease-in-out;
}

@media only screen and (max-width: 1140px) {
  .icon {
    height: 30px;
  }
}

@media only screen and (max-width: 940px) {
  .icon {
    height: 25px;
  }
}

@media only screen and (max-width: 738px) {
  .icon {
    height: 19px;
  }
}

@media only screen and (max-width: 620px) {
  .icon {
    height: 16px;
  }
}

@media only screen and (max-width: 516px) {
  .icon {
    height: 12px;
  }
}
@media only screen and (max-width: 375px) {
  .icon {
    height: 9px;
  }
}
