.grey {
  font-family: "Oswald";
  padding: 2%;
  font-weight: 300;
  font-size: 1vw;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  background-color: #4d4d4d;
}

.timot {
  width: 50%;
}

.kontakt-info {
  width: 15%;
}
.garantirame {
  font-family: "Oswald";
  color: #fff;
  font-weight: 200;
  font-size: 1vw;
  padding: 1% 0;
  background-color: #1a5e8c;
  text-align: center;
}

.agenti-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.agent {
  padding: 2%;
}

@media only screen and (max-width: 768px) {
  .grey {
    flex-direction: column;
    font-size: 0.9em;
  }
  .kontakt-info {
    width: 80%;
    padding-top: 5%;
  }
  .garantirame {
    font-size: 0.5em;
  }
  .timot {
    width: 100%;
  }
}
