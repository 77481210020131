.uslugi-container {
  margin: 0 auto;
  margin-top: 5%;
  padding: 0;
  width: 70%;
  font-family: "Oswald";
  color: #1a5e8c;
  text-align: left;
}

.uslugi-title {
  margin-left: 0;
  font-size: 1.8vw;
}

.ulsugi-list {
  font-size: 1.3vw;
  text-align: left;
  font-weight: 300;
}

@media only screen and (max-width: 728px) {
  .uslugi-title {
    margin-left: 0;
    font-size: 1em;
  }
  .ulsugi-list {
    font-size: 0.6em;
  }
  .uslugi-container {
    height: 100vh;
  }
}
