.part-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.part-logos {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 7%px;
}

.part-logos a {
  width: 25%;
  text-align: center;
}

.logo-part {
  transform: perspective(3em) rotateX(0deg) rotateY(-1.175deg) rotateZ(-1deg);
  transition: 0.5s ease;
  width: 100%;
}

.logo-part:hover {
  transform: perspective(0em) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  filter: none;
}
.part-title {
  font-family: "Oswald";
  box-sizing: border-box;
  margin: 0;
  margin-bottom: 5%;
  padding-top: 2%;
  font-weight: 700;
  font-size: 3vw;
  color: #1a5e8c;
}

@media only screen and (max-width: 768px) {
  .part-title {
    font-size: 1.3em;
  }
  .part-logos a {
    width: 50%;
  }
}
