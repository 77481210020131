.regulativa-container {
  margin: 0 auto;
  margin-top: 5%;
  padding: 0;
  width: 70%;
  font-family: "Oswald";
  color: #1a5e8c;
  text-align: left;
}

.regulativa-title {
  margin-left: 0;
  font-size: 1.8vw;
}
.regulativa-text {
  font-weight: 300;
  color: #1a5e8c;
  font-size: 1.3vw;
  margin: 0 auto;
  text-align: left;
  width: 100%;
}

.regulativa-zakon {
  margin-top: 10%;
}

.regulativa-zakon img {
  width: 3%;
  height: auto;
}

.regulativa-zakon a {
  font-size: 1.3vw;
  color: #1a5e8c;
}

@media only screen and (max-width: 728px) {
  .regulativa-container {
    height: 43vh;
  }
  .regulativa-title {
    font-size: 1em;
  }
  .regulativa-text {
    font-size: 0.6em;
  }
  .regulativa-zakon a {
    font-size: 0.6em;
  }
}
