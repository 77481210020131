.zanas-container {
  margin: 0 auto;
  margin-top: 5%;
  padding: 0;
  width: 70%;
  font-family: "Oswald";
  color: #1a5e8c;
  text-align: left;
}

.zanas-title {
  margin-left: 0;
  font-size: 1.8vw;
}

.zanas-text {
  color: #1a5e8c;
  font-size: 1.3vw;
  margin: 0 auto;
  text-align: left;
  width: 100%;
}

.prv {
  text-align: left;
  font-size: 1.3vw;
}
.predmet {
  text-align: center;
  font-size: 1.3vw;
}
.nadomest {
  text-align: center;
  font-size: 1.3vw;
}

.posledno {
  text-align: left;
}

@media only screen and (max-width: 728px) {
  .zanas-title {
    font-size: 1em;
  }
  .prv {
    font-size: 0.6em;
  }
  .predmet {
    font-size: 0.6em;
  }
  .nadomest {
    font-size: 0.6em;
  }
  .zanas-text {
    font-size: 0.6em;
  }
}
