.kontakt-container {
  display: flex;
  margin: 0 auto;
  margin-top: 5%;
  padding: 0;
  width: 70%;
  font-family: "Oswald";
  justify-content: center;
}

.kontakt-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: #1a5e8c;
  text-align: center;
  width: 30%;
}

.kon-tel h3 {
  margin: 0;
  font-size: 1em;
}
.agenti {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
}

.agent h4 {
  margin: 0;
  font-size: 1em;
}

.agent-mail {
  color: #1a5e8c;
  text-decoration: underline;
  font-size: 1e;
  font-weight: 300;
}

.agent-mail:hover {
  color: #f05a29;
}

.logo-info img {
  margin-top: 30%;
}

.kontakt-right {
  display: block;
  width: 40%;
  margin-left: 10%;
  background-color: #1a5e8c;
}
.poraka-form-container {
  border-radius: 5px;
  padding: 20px;
}

.poraka-form-container h4 {
  color: #fff;
  font-size: 1.3vw;
  margin: 0;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  font-family: "Oswald";
  color: #1a5e8c;
  margin-top: 2%;
  box-sizing: border-box;
  width: 100%;
  padding: 5px;
  border: 1px solid #fff;
  border-radius: 3px;
  resize: vertical;
  font-size: 1vw;
}

input[type="submit"] {
  background-color: #f05a29;
  font-family: "Oswald";
  font-weight: 900;
  font-size: 1vw;
  margin-bottom: 2%;
  color: #fff;
  padding: 2%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
  width: 100%;
  transition: 0.3s ease-in-out;
}

input[type="submit"]:hover {
  background-color: #e04a18;
}

@media only screen and (max-width: 768px) {
  .kontakt-container {
    margin-top: 40%;
    height: 60vh;
  }
  .kontakt-left {
    height: 50%;
  }
}
