@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --main-color: #1a5e8c;
}

body {
  background-image: linear-gradient(
    90deg,
    #1a5e8c 10%,
    white 10%,
    white 90%,
    #1a5e8c 90%
  );
  height: 100vh;
}

.br-line {
  margin-top: 2%;
  margin-bottom: 2%;
  border: 1px solid #f05a29;
  width: 74%;
}

.load-container {
  display: flex;
  justify-content: center;

  height: 100vh;
}
.loadLogo {
  margin: 0 auto;
  height: 100vh;
}

.header {
  display: flex;
  align-items: center;
}

.number {
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  box-sizing: border-box;
  text-align: center;
  width: 25%;
  color: #fff;
  background-color: #1a5e8c;
  font-size: 2.3vw;
}

.logo {
  width: 4%;
  padding: 0 10px;
}

.title {
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  font-size: 2.5vw;
  box-sizing: border-box;
  text-align: center;
  width: 50%;
  color: #fff;
  background-color: #1a5e8c;
  border-radius: 0 30px 30px 0;
}

.social_icons {
  display: flex;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  margin-right: 1%;
}
.icon {
  height: 36px;
  transition: 0.5s ease-in-out;
}

@media only screen and (max-width: 1140px) {
  .icon {
    height: 30px;
  }
}

@media only screen and (max-width: 940px) {
  .icon {
    height: 25px;
  }
}

@media only screen and (max-width: 738px) {
  .icon {
    height: 19px;
  }
}

@media only screen and (max-width: 620px) {
  .icon {
    height: 16px;
  }
}

@media only screen and (max-width: 516px) {
  .icon {
    height: 12px;
  }
}
@media only screen and (max-width: 375px) {
  .icon {
    height: 9px;
  }
}

.navbar {
  background-color: #f05a29;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.info {
  font-family: "Oswald", sans-serif;
  font-weight: 200;
  position: absolute;
  left: 2%;
  transition: 0.4s;
  font-size: 1vw;
}

.info:hover {
  color: #1a5e8c;
}
a {
  color: #fff;
  text-decoration: none;
}
.navbar ul {
  margin: 0;
  font-family: "Oswald", sans-serif;
  font-weight: 200;
  list-style: none;
  display: flex;
  flex-direction: row;
}

.navlink {
  padding: 0px 30px;
  font-size: 1rem;
  font-weight: 300;
  transition: 0.3s ease-in-out;
}

.navlink:hover {
  background-color: #1a5e8c;
  transition: 0.7s ease;
}
.active {
  background-color: #1a5e8c;
}
@media only screen and (max-width: 1200px) {
  .navlink {
    font-size: 0.7rem;
  }
}

@media only screen and (max-width: 768px) {
  .navlink,
  .info {
    display: none;
  }
}

.showcase {
  font-family: "Oswald", sans-serif;
  margin: 0 auto;
  margin-top: 1%;
  background-image: url(/static/media/main_img.5c502944.jpg);
  width: 80%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.show_title {
  box-sizing: border-box;
  margin: 0;
  padding-top: 3%;
  font-weight: 300;
  font-size: 3em;
  color: #1a5e8c;
  margin-left: 5%;
  text-shadow: 3px 3px 3px rgb(255, 254, 254);
}
.title2 {
  margin: 0;
  font-weight: 200;
  font-size: 2em;
  color: #1a5e8c;
  margin-left: 5%;
  text-shadow: 2px 2px 1px rgb(255, 254, 254);
}

.contact-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 13%;
  background: rgba(26, 94, 140, 0.43);
}
.contact-text {
  margin: 0;
  padding: 1%;
  margin-left: 5%;
  font-weight: 500;
  font-size: 2em;
  color: #fff;
}

p {
  margin: 0;
  padding-top: 0;
  padding: 1%;
  margin-left: 5%;
  font-weight: 200;
  font-size: 1.5em;
  color: #fff;
  width: 60%;
}

.contact-a {
  display: inline-block;
  background-color: #f05a29;
  box-sizing: border-box;
  font-size: 1em;
  border-radius: 0.5em;
  font-family: "Oswald", sans-serif;
  color: #fff;
  margin-left: auto;
  margin-bottom: 5%;
  margin-right: 5%;
  text-align: center;
  padding: 0.35em 1.2em;
  transition: 0.5s ease-in-out;
  z-index: 8;
}

.contact-a:hover {
  background-color: #1a5e8c;
}

.contact-a:active {
  transform: scale(0.95);
}

@media only screen and (max-width: 414px) {
  .showcase {
    background-position-x: -250px;
    background-position-y: 100px;
    background-size: 750px;
  }
  .contact-a {
    display: block;
    margin: 0 auto;
    width: 100%;
    margin-top: 5%;
  }
  .show_title {
    text-align: center;
    padding-top: 5%;
    margin-left: 0;
    font-size: 2em;
    line-height: 1.2;
    width: 80%;
    margin: 0 auto;
  }
  .title2 {
    text-align: center;
    margin-left: 0;
    font-size: 1.3em;
    margin-top: 5%;
    font-weight: 300;
    padding: 2%;
  }
  p {
    margin: 0;
    width: 100%;
    padding: 0;
    font-size: 1.1em;
    text-align: center;
  }

  .contact-container {
    margin-top: 50%;
  }
  .contact-text {
    text-align: center;
    font-size: 1.3em;
  }
}

.ponuda-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #1a5e8c;
  height: 100%;
}

.pon-title {
  font-family: "Oswald";
  box-sizing: border-box;
  margin: 0;
  margin-bottom: 2%;
  margin-top: 5%;
  padding-top: 2%;
  font-weight: 700;
  font-size: 3vw;
  color: #fff;
}

.cards-container {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #1a5e8c;
  cursor: pointer;
}
.card {
  width: 400px;
  display: flex;
  flex-direction: column;
  margin-top: 1%;
  box-sizing: border-box;
  padding: 2%;
  transition: 0.5s ease;
  box-shadow: 0px 7px 10px rgba(black, 0.5);
}
.card:hover {
  transform: translateY(-10px);
}

.card:hover:before {
  opacity: 1;
}

.card:hover .text-ponuda {
  opacity: 1;
  transform: translateY(0px);
}

.text-ponuda {
  font-family: "Oswald";
  font-size: 1vw;
  text-align: center;
  margin: 0 auto;
  width: 90%;
  position: relative;
  z-index: 3;
  color: white;
  opacity: 0;
  transform: translateY(-40px);
  transition: 0.5s;
}

.card:before {
  content: "";
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 0;
  transition: 0.5s;
  opacity: 0;
}

.name-ponuda {
  box-sizing: border-box;
  font-family: "Oswald";
  font-weight: 300;
  font-size: 1.1vw;
  text-align: center;
  color: #fff;
  background-color: #1a5e8c;
  width: 380px;
  margin: auto;
  padding-top: 3%;
}

.img-ponuda {
  width: 100%;
  box-shadow: 0px 0px 18px -3px rgba(143, 143, 143, 0.5);
  border-radius: 15px;
}
.img-ponuda:hover {
  box-shadow: 0px 0px 20px -3px rgba(255, 255, 255, 0.75);
}

@media only screen and (max-width: 1000px) {
  .card {
    width: 200px;
  }
  .name-ponuda {
    width: 180px;
  }
}

@media only screen and (max-width: 768px) {
  .name-ponuda {
    font-size: 1em;
  }
  .pon-title {
    font-size: 1.3em;
  }
  .cards-container {
    width: 100%;
  }
  .text-ponuda {
    font-size: 0.5em;
  }
}

.part-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.part-logos {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 7%px;
}

.part-logos a {
  width: 25%;
  text-align: center;
}

.logo-part {
  transform: perspective(3em) rotateX(0deg) rotateY(-1.175deg) rotateZ(-1deg);
  transition: 0.5s ease;
  width: 100%;
}

.logo-part:hover {
  transform: perspective(0em) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  -webkit-filter: none;
          filter: none;
}
.part-title {
  font-family: "Oswald";
  box-sizing: border-box;
  margin: 0;
  margin-bottom: 5%;
  padding-top: 2%;
  font-weight: 700;
  font-size: 3vw;
  color: #1a5e8c;
}

@media only screen and (max-width: 768px) {
  .part-title {
    font-size: 1.3em;
  }
  .part-logos a {
    width: 50%;
  }
}

.grey {
  font-family: "Oswald";
  padding: 2%;
  font-weight: 300;
  font-size: 1vw;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  background-color: #4d4d4d;
}

.timot {
  width: 50%;
}

.kontakt-info {
  width: 15%;
}
.garantirame {
  font-family: "Oswald";
  color: #fff;
  font-weight: 200;
  font-size: 1vw;
  padding: 1% 0;
  background-color: #1a5e8c;
  text-align: center;
}

.agenti-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.agent {
  padding: 2%;
}

@media only screen and (max-width: 768px) {
  .grey {
    flex-direction: column;
    font-size: 0.9em;
  }
  .kontakt-info {
    width: 80%;
    padding-top: 5%;
  }
  .garantirame {
    font-size: 0.5em;
  }
  .timot {
    width: 100%;
  }
}

.zanas-container {
  margin: 0 auto;
  margin-top: 5%;
  padding: 0;
  width: 70%;
  font-family: "Oswald";
  color: #1a5e8c;
  text-align: left;
}

.zanas-title {
  margin-left: 0;
  font-size: 1.8vw;
}

.zanas-text {
  color: #1a5e8c;
  font-size: 1.3vw;
  margin: 0 auto;
  text-align: left;
  width: 100%;
}

.prv {
  text-align: left;
  font-size: 1.3vw;
}
.predmet {
  text-align: center;
  font-size: 1.3vw;
}
.nadomest {
  text-align: center;
  font-size: 1.3vw;
}

.posledno {
  text-align: left;
}

@media only screen and (max-width: 728px) {
  .zanas-title {
    font-size: 1em;
  }
  .prv {
    font-size: 0.6em;
  }
  .predmet {
    font-size: 0.6em;
  }
  .nadomest {
    font-size: 0.6em;
  }
  .zanas-text {
    font-size: 0.6em;
  }
}

.uslugi-container {
  margin: 0 auto;
  margin-top: 5%;
  padding: 0;
  width: 70%;
  font-family: "Oswald";
  color: #1a5e8c;
  text-align: left;
}

.uslugi-title {
  margin-left: 0;
  font-size: 1.8vw;
}

.ulsugi-list {
  font-size: 1.3vw;
  text-align: left;
  font-weight: 300;
}

@media only screen and (max-width: 728px) {
  .uslugi-title {
    margin-left: 0;
    font-size: 1em;
  }
  .ulsugi-list {
    font-size: 0.6em;
  }
  .uslugi-container {
    height: 100vh;
  }
}

.kontakt-container {
  display: flex;
  margin: 0 auto;
  margin-top: 5%;
  padding: 0;
  width: 70%;
  font-family: "Oswald";
  justify-content: center;
}

.kontakt-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: #1a5e8c;
  text-align: center;
  width: 30%;
}

.kon-tel h3 {
  margin: 0;
  font-size: 1em;
}
.agenti {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
}

.agent h4 {
  margin: 0;
  font-size: 1em;
}

.agent-mail {
  color: #1a5e8c;
  text-decoration: underline;
  font-size: 1e;
  font-weight: 300;
}

.agent-mail:hover {
  color: #f05a29;
}

.logo-info img {
  margin-top: 30%;
}

.kontakt-right {
  display: block;
  width: 40%;
  margin-left: 10%;
  background-color: #1a5e8c;
}
.poraka-form-container {
  border-radius: 5px;
  padding: 20px;
}

.poraka-form-container h4 {
  color: #fff;
  font-size: 1.3vw;
  margin: 0;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  font-family: "Oswald";
  color: #1a5e8c;
  margin-top: 2%;
  box-sizing: border-box;
  width: 100%;
  padding: 5px;
  border: 1px solid #fff;
  border-radius: 3px;
  resize: vertical;
  font-size: 1vw;
}

input[type="submit"] {
  background-color: #f05a29;
  font-family: "Oswald";
  font-weight: 900;
  font-size: 1vw;
  margin-bottom: 2%;
  color: #fff;
  padding: 2%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
  width: 100%;
  transition: 0.3s ease-in-out;
}

input[type="submit"]:hover {
  background-color: #e04a18;
}

@media only screen and (max-width: 768px) {
  .kontakt-container {
    margin-top: 40%;
    height: 60vh;
  }
  .kontakt-left {
    height: 50%;
  }
}

.regulativa-container {
  margin: 0 auto;
  margin-top: 5%;
  padding: 0;
  width: 70%;
  font-family: "Oswald";
  color: #1a5e8c;
  text-align: left;
}

.regulativa-title {
  margin-left: 0;
  font-size: 1.8vw;
}
.regulativa-text {
  font-weight: 300;
  color: #1a5e8c;
  font-size: 1.3vw;
  margin: 0 auto;
  text-align: left;
  width: 100%;
}

.regulativa-zakon {
  margin-top: 10%;
}

.regulativa-zakon img {
  width: 3%;
  height: auto;
}

.regulativa-zakon a {
  font-size: 1.3vw;
  color: #1a5e8c;
}

@media only screen and (max-width: 728px) {
  .regulativa-container {
    height: 43vh;
  }
  .regulativa-title {
    font-size: 1em;
  }
  .regulativa-text {
    font-size: 0.6em;
  }
  .regulativa-zakon a {
    font-size: 0.6em;
  }
}

